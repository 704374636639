import React from 'react';
import { CrimeIndexViewContext, TemplateProps } from '../parser';
import { PathService } from '../services';
import { CrimeIndexView } from '../views';

const CrimeIndex: React.FunctionComponent<TemplateProps<
  CrimeIndexViewContext
>> = ({ location, pageContext }) => {
  PathService.set(location.pathname);
  return (
    <CrimeIndexView
      content={JSON.parse(pageContext.content)}
      page={JSON.parse(pageContext.page)}
    />
  );
};

export default CrimeIndex;
